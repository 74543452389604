interface AppraiseLike {
  starRating?: number | null,
  star_rating?:number | null, 
  serviceStarRating?: number | null,
  service_star_rating?: number | null,
  logisticsStarRating?: number | null,
  logistics_star_rating?: number | null,
}

/**
 * 将评论星级转换为星数
 * @param item 评论数据或原星级数（1-10）
 * @param defaultValue item 无法处理时的默认值
 * @param allowHalf 可否半星
 * @returns 星数量
 */
export function getNumberOfStar(item: AppraiseLike | number | null, defaultValue = 10, allowHalf = false) {
  if (!item) {
    return defaultValue / 2;
  }
  let count = 0;
  if (typeof item === 'number') {
    count = item;
  } else {
    let sum = 0;
    if (item.logisticsStarRating) {
      count += item.logisticsStarRating;
      sum++;
    }
    else if (item.logistics_star_rating) {
      count += item.logistics_star_rating;
      sum++;
    }
    if (item.serviceStarRating) {
      count += item.serviceStarRating;
      sum++;
    }
    else if (item.service_star_rating) {
      count += item.service_star_rating;
      sum++;
    }
    if (item.starRating) {
      count += item.starRating;
      sum++;
    }
    else if (item.star_rating) {
      count += item.star_rating;
      sum++;
    }
    if (sum) {
      count /= sum;
    }
  }

  if (!count) {
    return defaultValue / 2;
  }
  count /= 2;
  if (allowHalf) {
    return count;
  }
  return Number(count.toFixed());
}